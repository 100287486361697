/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pc: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 0a1 1 0 00-1 1v14a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1zm.5 14a.5.5 0 110 1 .5.5 0 010-1m2 0a.5.5 0 110 1 .5.5 0 010-1M5 1.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5M5.5 3h5a.5.5 0 010 1h-5a.5.5 0 010-1"/>',
    },
});
